<template>
	<div class="mt-15">
		<div
			class="text-h5 text-md-h4 font-weight-bold wr_indigo_2--text"
			v-html="text"
		></div>
		<v-divider v-if="divider" class="mt-4 mb-8 wr-divider rounded"></v-divider>
	</div>
</template>

<script>
export default {
	name: "WebsiteTitle",

	props: {
		text: {
			required: true,
		},
		divider: {
			default: true,
		},
	},
};
</script>

<style scoped>
.wr-divider {
	border: 2px solid var(--v-wr_indigo_2-base) !important;
}
</style>
